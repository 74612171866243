.slideinBox {
   animation-delay: 0.5s;
   background-image: linear-gradient(
      90deg,
      rgb(255, 255, 255, 0) 0%,
      rgb(255, 255, 255, 0) 50%,
      #162c44 50.1%,
      #162c44 100%
   );
   background-size: auto auto;
   background-clip: border-box;
   background-size: 200% auto;
   color: rgb(255, 255, 255, 0);
   background-clip: text;
   text-fill-color: transparent;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   animation-name: slidein;
   animation-duration: 2s;
   display: inline-block;
   animation-fill-mode: forwards;
   position: relative;
   left: -100px;
}

@keyframes slidein {
   80% {
      left: -100px;
      background-position: 0% center;
   }
   to {
      background-position: 100% center;
      left: 0px;
   }
}

/* @keyframes slidein {
  0% { margin-left:350px; opacity: 0; }
  20% { margin-left:350px; opacity: 0; }
  35% { margin-left:0px; opacity: 0.75; }
  100% { margin-left:0px; opacity: 1; }
} */
