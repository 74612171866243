body {
   overflow-x: hidden;
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
   font-family: "Regular";
   src: url("./assets/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
   font-family: "Bold";
   src: url("./assets/fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
   font-family: "Italic";
   src: url("./assets/fonts/OpenSans/OpenSans-Italic.ttf") format("truetype");
}

@font-face {
   font-family: "BoldItalic";
   src: url("./assets/fonts/OpenSans/OpenSans-BoldItalic.ttf") format("truetype");
}

@font-face {
   font-family: "ExtraBold";
   src: url("./assets/fonts/OpenSans/OpenSans-ExtraBold.ttf") format("truetype");
}

@font-face {
   font-family: "SemiBold";
   src: url("./assets/fonts/OpenSans/OpenSans-Semibold.ttf") format("truetype");
}

.container {
   font-family: "Regular";
   color: "#162c44";
}
