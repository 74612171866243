body {
    max-width: 100%;
    overflow-x: hidden;
}

.tracker {
    overflow-x: hidden;
    position: absolute;
    top: 0;
	  transform: translate(calc(10px * var(--x)), calc(10px * var(--y)));   
    transition: transform 0.1s;
}